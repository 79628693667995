/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Modal, Form } from 'semantic-ui-react';
import { number, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@tg/core/components';
import { capitalise } from '@tg/core/utils/stringHelpers';

const Component = ({ onSubmit, onCancel, event_types, getEditableData }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(
      object().shape({
        year: number().required(),
      }),
    ),
  });

  const getDefaultValues = getEditableData();

  const getUniqueEvents = event_types?.filter(
    event =>
      !getDefaultValues?.planneryear_leaves.some(
        item => item.leave_type_id === event.id,
      ),
  );
  const addEvents =
    getDefaultValues?.planneryear_leaves?.concat(getUniqueEvents);

  const handleKeyPress = event => {
    const char = event.key;
    if (
      !/[\d.]/.test(char) ||
      (char === '.' && event.target.value.includes('.'))
    ) {
      event.preventDefault();
    }
  };

  return (
    <Modal as={Form} onSubmit={handleSubmit(onSubmit)} onClose={onCancel} open>
      <Modal.Header>Add Planner</Modal.Header>
      <Modal.Content>
        <Form.Field required error={!!errors.year}>
          <label htmlFor='year'>Year</label>
          <input
            name='year'
            type='text'
            ref={register}
            defaultValue={getDefaultValues?.year ? getDefaultValues?.year : ''}
          />
          {errors.year && <p>Required value</p>}
        </Form.Field>
        {!getDefaultValues?.planneryear_leaves
          ? event_types?.map(({ id, event_type }) => (
              <Form.Field key={id}>
                <label htmlFor={id}>{capitalise(event_type)}</label>
                <input
                  type='text'
                  id={id}
                  name={id}
                  ref={register}
                  onKeyPress={handleKeyPress} // Restrict input
                  defaultValue={0}
                />
              </Form.Field>
            ))
          : addEvents?.map(
              ({
                id,
                leave_type,
                leave_type_value,
                event_type,
                leave_type_id,
              }) =>
                leave_type === 'public-holidays' ? null : (
                  <Form.Field key={id}>
                    <label htmlFor={id}>
                      {leave_type
                        ? capitalise(leave_type)
                        : capitalise(event_type)}
                    </label>
                    <input
                      type='text'
                      id={id}
                      name={leave_type_id || id}
                      onKeyPress={handleKeyPress} // Restrict input
                      defaultValue={leave_type_value || 0}
                      ref={register}
                    />
                  </Form.Field>
                ),
            )}
      </Modal.Content>
      <Modal.Actions>
        <div className='space-x-2'>
          <Button color='secondary' onClick={onCancel}>
            Cancel
          </Button>
          <Button type='submit'>Next</Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

Component.propTypes = {
  data: PropTypes.shape({
    year: PropTypes.number,
    sickday_entitlement: PropTypes.number,
    holiday_entitlement: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

Component.defaultProps = {
  data: {
    year: null,
    sickday_entitlement: null,
    holiday_entitlement: null,
  },
};

export default Component;
